import { intervalToDuration } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { formatDuration } from './format';

type Milliseconds = number;

export type FormatDurationFunction = (
  duration: Duration,
  options?:
    | {
        format?: string[] | undefined;
        zero?: boolean | undefined;
        delimiter?: string | undefined;
        locale?: Locale | undefined;
      }
    | undefined,
) => string;

export function durationAsText(duration: Milliseconds, locale: Locale = enUS) {
  const minute = 60000;
  const hour = 3600000;
  const day = 86400000;

  if (duration < minute)
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ['days', 'hours', 'minutes', 'seconds'],
      locale,
    });
  if (duration > minute && duration < hour)
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ['days', 'hours', 'minutes'],
      locale,
    });
  if (duration > hour && duration < day)
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ['days', 'hours', 'minutes'],
      locale,
    });
  else {
    return formatDuration(intervalToDuration({ start: 0, end: duration }), {
      format: ['days', 'hours', 'minutes'],
      locale,
    });
  }
}
