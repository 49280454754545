import {
  format as orgFormat,
  durationAsText as orgDurationAsText,
  formatDistance as orgFormatDistance,
  formatDuration as orgFormatDuration,
} from '@santicon/common/shared/utils';
import { enUS, fi, enGB } from 'date-fns/locale';

const locales: { [code: string]: Locale } = {
  en: enUS,
  'en-GB': enGB,
  'fi-FI': fi,
  fi: fi,
};

export function format(date: Date, formatStr = 'PP'): string {
  const locale = (navigator && locales[navigator.language]) || enUS;
  return orgFormat(date, formatStr, locale);
}

export function formatDistance(
  date: Date | number,
  baseDate: Date | number,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
  },
) {
  const locale = (navigator && locales[navigator.language]) || enUS;
  return orgFormatDistance(date, baseDate, { locale, ...options });
}

export function formatDuration(
  duration: Duration,
  options?: {
    format?: string[];
    zero?: boolean;
    delimiter?: string;
    locale?: Locale;
  },
) {
  const locale = (navigator && locales[navigator.language]) || enUS;
  return orgFormatDuration(duration, { locale, ...options });
}

type Milliseconds = number;

export function durationAsText(duration: Milliseconds) {
  const locale = (navigator && locales[navigator.language]) || enUS;
  return orgDurationAsText(duration, locale);
}

export function durationAsHours(duration: number) {
  const locale = (navigator && navigator.language) || 'en-US';
  return (
    (duration / 3600000).toLocaleString(locale, { maximumFractionDigits: 2 }) +
    'h'
  );
}
