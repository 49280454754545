import { BeaconEvent } from '@santicon/common/aherrus/models';
import { ListItem } from '@santicon/web/shared/ui';
import { MdFlightLand, MdFlightTakeoff } from 'react-icons/md';
import Link from 'next/link';
import { format, formatDistance } from '@santicon/web/shared/utils';
import { useRouter } from 'next/router';
import { useSynchronizedInterval } from 'interval-hooks';
import { useState } from 'react';

/* eslint-disable-next-line */
export interface LocationEventListProps {
  locationEvents: BeaconEvent[];
}

export function LocationEventList({ locationEvents }: LocationEventListProps) {
  const [now, setNow] = useState(new Date());
  const router = useRouter();

  useSynchronizedInterval(() => {
    setNow(new Date());
  }, 1000);
  return (
    <ol className="divide-y divide-gray-500">
      {locationEvents
        ? locationEvents.map(locationEvent => {
            return (
              <li key={locationEvent.id}>
                <Link
                  href={{
                    pathname: router.pathname,
                    query: { ...router.query, editEvent: locationEvent.id },
                  }}
                  shallow
                  passHref
                  replace
                >
                  <ListItem
                    as="a"
                    icon={
                      locationEvent.state === 'entered' ? (
                        <MdFlightLand />
                      ) : (
                        <MdFlightTakeoff />
                      )
                    }
                    firstRow={locationEvent.identifier}
                    secondRow={`${format(
                      locationEvent.timestamp,
                      'PP p',
                    )} ${formatDistance(locationEvent.timestamp, now, {
                      addSuffix: true,
                    })}`}
                  />
                </Link>
              </li>
            );
          })
        : null}
    </ol>
  );
}

export default LocationEventList;
