import { MdClose } from 'react-icons/md';

/* eslint-disable-next-line */
export interface TagProps {}

export interface Tag {
  id: string;
  name: string;
  color?: string;
  value?: string;
}

export function TagBadge({
  tag,
  onRemove,
  showDelete = true,
  size = 'medium',
  ...rest
}: {
  tag: Tag;
  showDelete?: boolean;
  size?: 'small' | 'medium';
  onRemove?: (tag: Tag) => void;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...rest}
      data-testid="tag-badge"
      className={`inline-flex items-center justify-center rounded-full bg-secondary-200 font-semibold text-primary-800 ${
        size === 'small' ? 'py-0 pl-3 pr-1 text-sm ' : 'py-1 pl-4 pr-2'
      }`}
    >
      <span className="mr-2">{tag.name}</span>
      {onRemove && showDelete ? (
        <button
          aria-label={`delete tag ${tag.name}`}
          type="button"
          onClick={() => onRemove(tag)}
        >
          <MdClose className="-ml-1 h-full" />
        </button>
      ) : null}
    </div>
  );
}

export default TagBadge;
