import { useState, Fragment } from 'react';
import { Combobox } from '@headlessui/react';
import { FaChevronDown } from 'react-icons/fa';
import TagBadge, { Tag } from '../tag-badge/tag-badge';

export interface TagInputProps {
  maxLength?: number;
  delimiters?: string[];
  allTags: Tag[];
  tags: Tag[];
  placeholder?: string;
  label: string;
  required?: boolean;
  addEnabled?: boolean;
  onAddTag: (tag: string | Tag) => void;
  onRemoveTag: (tag: Tag) => void;
}

export function TagInput({
  maxLength,
  delimiters = [','],
  tags,
  allTags,
  placeholder = 'Add a tag',
  label,
  required,
  onAddTag,
  onRemoveTag: onDeleteTag,
  addEnabled = true,
}: TagInputProps) {
  const [query, setQuery] = useState('');

  const availableTags = tags
    ? allTags.filter(tag => tags.findIndex(t => t.id === tag.id) === -1)
    : allTags;

  const filteredTags =
    query === ''
      ? availableTags
      : [
          ...availableTags.filter((tag: Tag) => {
            return tag.name.toLowerCase().includes(query.toLowerCase());
          }),
        ];

  if (
    addEnabled &&
    query !== '' &&
    filteredTags.findIndex(tag => tag.name === query) === -1 &&
    tags.findIndex(t => t.name === query) === -1
  ) {
    filteredTags.push({ id: 'NEW', name: query });
  }

  const handleOnChange = (tag: Tag | string) => {
    if (typeof tag === 'string') {
      onAddTag(tag);
    } else if (tag.id === 'NEW') {
      onAddTag(tag.name);
    } else {
      onAddTag(tag);
    }
    setQuery('');
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    const clipboardText = clipboardData.getData('text');

    const maxTextLength = Math.min(
      maxLength ?? clipboardText.length,
      clipboardText.length,
    );
    const pastedText = clipboardData
      .getData('text')
      .substring(0, maxTextLength);

    // Used to determine how the pasted content is split.
    const delimiterRegExp = new RegExp(delimiters.join('|'), 'g');
    const pastedTags = pastedText.split(delimiterRegExp);

    // Only add unique pastedTags
    const uniqueTags = new Set(pastedTags.map((tag: string) => tag.trim()));

    // Only add unique tags that are not already in tags
    uniqueTags.forEach(tag =>
      tags.map(({ name }) => name !== tag && onAddTag(tag)),
    );
  };

  return (
    <div className="flex flex-col gap-1 text-gray-800">
      <Combobox value={query} onChange={handleOnChange}>
        <Combobox.Label>
          <span
            className={`dark:text-gray-0 ${
              required
                ? "after:ml-1 after:text-primary-800 after:content-['*'] dark:after:text-primary-150 "
                : ''
            }`}
          >
            {label}
          </span>
        </Combobox.Label>

        <div className="rounded-md border-2 border-gray-900 bg-gray-0 p-2 shadow-inner  ">
          <div
            style={{ minHeight: '2em' }}
            className="focus-visible:ring-offset-teal-300 flex cursor-default flex-wrap items-center gap-2 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2"
          >
            {tags
              ? tags.map((tag, index) => (
                  <TagBadge key={index} tag={tag} onRemove={onDeleteTag} />
                ))
              : null}

            <div className="relative inline-block min-w-max flex-1 ">
              <Combobox.Input
                className="w-full bg-transparent px-1 focus:outline-secondary-300 dark:focus:outline-secondary-300"
                onChange={event => setQuery(event.target.value)}
                onPaste={handlePaste}
                placeholder={placeholder}
                autoComplete="off"
              ></Combobox.Input>
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <FaChevronDown
                  className="inline-block h-5 w-5"
                  aria-hidden="true"
                />
              </Combobox.Button>
              <Combobox.Options className="border-1 absolute z-10 w-full border-2 border-gray-800 bg-gray-0 p-1">
                {allTags
                  ? filteredTags.map(tag => (
                      /* Use the `active` state to conditionally style the active option. */
                      /* Use the `selected` state to conditionally style the selected option. */
                      <Combobox.Option key={tag.id} value={tag} as={Fragment}>
                        {({ active }) => (
                          <li
                            className={`${
                              active
                                ? 'bg-gray-500 text-white'
                                : 'bg-white text-black'
                            } px-2`}
                          >
                            {tag.id === 'NEW' ? (
                              <span>
                                Create a new tag: <b>{tag.name}</b>
                              </span>
                            ) : (
                              tag.name
                            )}
                          </li>
                        )}
                      </Combobox.Option>
                    ))
                  : null}
              </Combobox.Options>
            </div>
          </div>
        </div>
      </Combobox>
    </div>
  );
}

export default TagInput;
