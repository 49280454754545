/* eslint-disable-next-line */
export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  button?: (
    props: React.ButtonHTMLAttributes<HTMLButtonElement>,
  ) => JSX.Element;
  label: string;
  placeholder: string;
  errorText?: string;
  id: string;
}

export function Input({
  icon: Icon,
  button: Button,
  placeholder,
  label,
  className,
  errorText,
  id,
  required,
  ...props
}: InputProps) {
  return (
    <div className={`relative flex flex-col gap-1 text-gray-900 ${className}`}>
      <label
        htmlFor={id}
        className={`dark:text-gray-0 ${
          required
            ? "after:ml-1 after:text-primary-800 after:content-['*'] dark:after:text-primary-150 "
            : ''
        }`}
      >
        {label}
      </label>
      <div
        className={`flex items-center rounded-md border-2 border-gray-900 bg-gray-0 p-2 shadow-inner focus-within:border-secondary-300 dark:focus-within:border-secondary-300 ${
          errorText ? 'border-2 border-error-500 outline-error-500  ' : ''
        }`}
      >
        {Icon ? <Icon className="m-2 h-full " /> : null}
        <input
          id={id}
          placeholder={placeholder}
          required={required}
          className={`w-full bg-transparent p-2 focus:outline-none`}
          {...props}
        />
        {Button ? <Button className="h-full p-2 " /> : null}
      </div>
      {errorText ? (
        <span className="self-start py-1 px-2 font-medium tracking-wide text-error-500 dark:bg-error-500 dark:text-error-0">
          {errorText}
        </span>
      ) : null}
    </div>
  );
}

export default Input;
