import Next from '../icons/next';
import Pause from '../icons/pause';
import Play from '../icons/play';
import Stop from '../icons/stop';
import { IconProps } from '../icons/types';
import Spinner from '../spinner/spinner';

/* eslint-disable-next-line */
export interface RunningButtonProps {
  variant: 'play' | 'pause' | 'stop' | 'next';
  loading?: boolean;
  disabled?: boolean;
  size?: number;
}

export function RunningButton({
  variant,
  size = 64,
  loading = false,
  disabled = false,
  ...derived
}: RunningButtonProps & React.ComponentPropsWithoutRef<'button'>) {
  let Icon: (props: IconProps & React.SVGProps<SVGSVGElement>) => JSX.Element;
  let color: string;

  switch (variant) {
    case 'play':
      Icon = Play;
      color = 'bg-accent-300 hover:bg-accent-200 text-accent-900';
      break;
    case 'pause':
      Icon = Pause;
      color = 'bg-accent-300 hover:bg-accent-200 text-accent-900';
      break;
    case 'stop':
      Icon = Stop;
      color = 'bg-error-500 hover:bg-error-200 text-error-900';
      break;
    case 'next':
      Icon = Next;
      color = 'bg-primary-300 hover:bg-primary-200 text-primary-800';
      break;

    default:
      Icon = Play;
      color = 'bg-accent-300 hover:bg-accent-200 text-accent-900';
      break;
  }
  return (
    <button
      disabled={disabled}
      aria-label={variant}
      className={`
        ${color} flex items-center justify-center rounded-full disabled:bg-gray-200 disabled:text-gray-700
        ${!disabled ? 'active:scale-95' : ''}`}
      style={{ width: `${size}px`, height: `${size}px` }}
      {...derived}
    >
      {loading ? (
        <Spinner size={`${size / 2}px`} className="dark" />
      ) : (
        <Icon size={`${size / 2}px`} />
      )}
    </button>
  );
}

export default RunningButton;
