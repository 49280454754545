import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export interface CardProps {}

export function Card({
  className: derivedClassName,
  children,
  ...props
}: CardProps & React.ComponentPropsWithoutRef<'div'>) {
  const className =
    'rounded-lg bg-gray-0 p-8 shadow dark:bg-gray-600 dark:text-gray-0';
  return (
    <div className={twMerge(className, derivedClassName)} {...props}>
      {children}
    </div>
  );
}

export default Card;
