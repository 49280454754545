import * as React from 'react';
import { IconProps } from './types';

function Play({
  size = '1em',
  ...props
}: IconProps & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M30 13.048L10.5 24.306V1.79L30 13.048z" fill="currentColor" />
    </svg>
  );
}

export default Play;
