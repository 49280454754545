import { hue2rgb } from './hue-to-rgb';

export function hslToRgb(hue: number, saturation: number, lightnest: number) {
  let r, g, b;

  if (saturation === 0) {
    r = g = b = lightnest; // achromatic
  } else {
    const q =
      lightnest < 0.5
        ? lightnest * (1 + saturation)
        : lightnest + saturation - lightnest * saturation;
    const p = 2 * lightnest - q;

    r = hue2rgb(p, q, hue + 1 / 3);
    g = hue2rgb(p, q, hue);
    b = hue2rgb(p, q, hue - 1 / 3);
  }

  return (
    '#' +
    Math.round(r * 255).toString(16) +
    Math.round(g * 255).toString(16) +
    Math.round(b * 255).toString(16)
  );
}
