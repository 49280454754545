import * as React from 'react';
import { IconProps } from './types';

function Pause({
  size = '1em',
  ...props
}: IconProps & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="currentColor" d="M7 3h6.667v20H7zM20.333 3H27v20h-6.667z" />
    </svg>
  );
}

export default Pause;
