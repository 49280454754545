import Link from 'next/link';

export function Footer() {
  return (
    <footer className="flex min-h-[64px] flex-col justify-center bg-gray-900 text-gray-0">
      <div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center">
        <a className="font-normal text-inherit" href="https://santicon.fi">
          Copyright © Santi Consulting Oy, 2022.
        </a>
        <Link href="/privacy-policy" passHref>
          <a className="text-sm font-thin text-inherit text-gray-400">
            Privacy Policy
          </a>
        </Link>
      </div>
    </footer>
  );
}
