import { getContrast, hashInt, hslToRgb } from '@santicon/common/shared/utils';
import initials from 'initials';

/* eslint-disable-next-line */
export interface AvatarProps {
  name: string | null;
  src: string | null;
  size?: number;
  className?: string;
}

export function Avatar({
  name,
  src,
  className: derivedClasses,
  size = 40,
}: AvatarProps) {
  if (!name) throw new Error('UserAvatar requires a name');

  const abbr = initials(name);
  const hue = hashInt(name, 360);
  const bgColor = hslToRgb(hue / 360, 1, 0.8);
  const contrast = getContrast(bgColor);

  return (
    <div
      className={`relative inline-flex  items-center justify-center rounded-full ${
        contrast === 'light' ? 'text-gray-0' : 'text-gray-900'
      } ${derivedClasses}`}
      style={{
        backgroundColor: bgColor,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <p>{abbr}</p>
      {src ? (
        <img
          src={src}
          aria-label={name}
          alt={name}
          style={{ width: `${size}px`, height: `${size}px` }}
          className={`absolute animate-fade-in rounded-full opacity-100`}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Avatar;
