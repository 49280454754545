import { SessionWithDurations } from '@santicon/common/aherrus/models';
import { calcDuration } from '@santicon/common/shared/utils';
import {
  durationAsHours,
  durationAsText,
  format,
} from '@santicon/web/shared/utils';

import { ListItem, ListItemContainer, TagBadge } from '@santicon/web/shared/ui';
import Link from 'next/link';
import { MdFreeBreakfast, MdHandyman } from 'react-icons/md';
import {
  ListChildComponentProps,
  VariableSizeList as List,
} from 'react-window';
import { twMerge } from 'tailwind-merge';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  isNewDay,
  isNewMonth,
  isNewWeek,
  isNewYear,
} from '../utils/session-utils';

/* eslint-disable-next-line */
export interface SessionListProps {
  sessions: SessionWithDurations[];
  path?: string;
  subHeaders?: ('year' | 'month' | 'week' | 'day')[];
}

function SubHeading({
  className: derivedClasses,
  title,
  workDuration,
  breakDuration,
}: {
  className?: string;
  title: string;
  workDuration: number;
  breakDuration: number;
}) {
  return (
    <ListItemContainer
      className={twMerge('flex h-[56px] justify-between', derivedClasses)}
    >
      <div>{title}</div>
      <div className="flex gap-2">
        {workDuration ? (
          <div className="flex items-center gap-2">
            <MdHandyman className="inline-block" />
            {durationAsHours(workDuration)}
          </div>
        ) : null}
        {breakDuration ? (
          <div className="flex items-center gap-2">
            <MdFreeBreakfast className="inline-block" />
            {durationAsHours(breakDuration)}
          </div>
        ) : null}
      </div>
    </ListItemContainer>
  );
}

function YearHeading({ session }: { session: SessionWithDurations }) {
  return (
    <SubHeading
      className="bg-gray-200 text-sm font-semibold dark:bg-gray-700"
      title={format(session.startTime, 'yyyy')}
      workDuration={session.year.work}
      breakDuration={session.year.break}
    />
  );
}

function MonthHeading({ session }: { session: SessionWithDurations }) {
  return (
    <SubHeading
      className="bg-gray-300 text-sm font-semibold dark:bg-gray-700/50"
      title={format(session.startTime, 'LLLL')}
      workDuration={session.month.work}
      breakDuration={session.month.break}
    />
  );
}

function WeekHeading({ session }: { session: SessionWithDurations }) {
  return (
    <SubHeading
      className="bg-gray-300/75 text-sm font-semibold dark:bg-gray-700/20"
      title={`Week ${format(session.startTime, 'I')}`}
      workDuration={session.week.work}
      breakDuration={session.week.break}
    />
  );
}

function DayHeading({ session }: { session: SessionWithDurations }) {
  return (
    <SubHeading
      className="bg-gray-400 text-sm text-gray-800 dark:bg-gray-200 dark:text-gray-800"
      title={format(session.startTime, 'PPPP')}
      workDuration={session.day.work}
      breakDuration={session.day.break}
    />
  );
}

export function SessionList({ sessions, path, subHeaders }: SessionListProps) {
  if (!sessions) return null;

  function SessionRow({
    data: sessions,
    index,
    style,
  }: ListChildComponentProps<SessionWithDurations[]>): JSX.Element {
    const session = sessions[index];
    return (
      <div key={session.id} style={style}>
        {subHeaders?.includes('year') && isNewYear(sessions, index, session) ? (
          <YearHeading session={session} />
        ) : null}
        {subHeaders?.includes('month') &&
        isNewMonth(sessions, index, session) ? (
          <MonthHeading session={session} />
        ) : null}
        {subHeaders?.includes('week') && isNewWeek(sessions, index, session) ? (
          <WeekHeading session={session} />
        ) : null}
        {subHeaders?.includes('day') && isNewDay(sessions, index, session) ? (
          <DayHeading session={session} />
        ) : null}
        <div>
          <Link href={`${path}/${session.id}`} passHref>
            <ListItem
              as="a"
              icon={
                session.type === 'work' ? <MdHandyman /> : <MdFreeBreakfast />
              }
              className="h-[56px]"
              firstRow={`${format(session.startTime, 'p')} - 
                      ${
                        session.endTime
                          ? format(session.endTime, 'p')
                          : 'onGoing'
                      }`}
              secondRow={
                <div className="flex gap-2">
                  {durationAsText(
                    calcDuration({
                      startTime: session.startTime,
                      endTime: session.endTime,
                    }),
                  )}
                  {session.description ? (
                    <span>{session.description}</span>
                  ) : null}
                  {session.tags &&
                    session.tags.length > 0 &&
                    session.tags.map(tag => (
                      <TagBadge key={tag.id} tag={tag} size="small" />
                    ))}
                </div>
              }
            />
          </Link>
        </div>
      </div>
    );
  }

  function getItemSize(index: number) {
    const session = sessions[index];
    let height = 56;

    if (isNewYear(sessions, index, session)) height += 56;
    if (isNewMonth(sessions, index, session)) height += 56;
    if (isNewWeek(sessions, index, session)) height += 56;
    if (isNewDay(sessions, index, session)) height += 56;

    console.log({ height });
    return height;
  }

  return (
    <div className="h-screen">
      <AutoSizer disableWidth>
        {({ height }) => (
          <List
            className="divide-y divide-gray-500"
            height={height}
            width="100%"
            itemSize={getItemSize}
            itemData={sessions}
            itemCount={sessions.length}
          >
            {SessionRow}
          </List>
        )}
      </AutoSizer>
    </div>
  );
}

export default SessionList;
