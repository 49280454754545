import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export type ListItemContainerProps<
  Element extends React.ElementType = React.ElementType,
> = {
  as?: Element;
  icon?: React.ReactNode;
  className?: string;
  button?: React.ReactNode;
  active?: boolean;
  children: React.ReactNode;
};

export type ListItemOwnProps<
  Element extends React.ElementType = React.ElementType,
> = {
  firstRow: string;
  secondRow?: React.ReactNode;
} & Omit<ListItemContainerProps<Element>, 'children'>;

export type ListItemProps<Element extends React.ElementType> =
  ListItemOwnProps<Element> &
    Omit<React.ComponentProps<Element>, keyof ListItemOwnProps>;

const defaultElement = 'li';

export const ListItemContainer: <
  Element extends React.ElementType = typeof defaultElement,
>(
  props: ListItemContainerProps<Element>,
) => React.ReactElement | null = forwardRef(function ListItemContainer(
  {
    as: Tag = defaultElement,
    className: derivedClassName,
    active = false,
    children,
    ...derived
  }: ListItemContainerProps,
  ref: React.Ref<Element>,
) {
  const className = `text-inherit font-normal [not]:last-of-type:border-b flex min-h-[3rem] items-center gap-4 px-2 py-1 dark:text-gray-0 ${
    Tag === 'a'
      ? 'hover:text-gray-700 dark:hover:text-gray-0 hover:bg-gray-100 dark:hover:bg-gray-500'
      : ' '
  }
${active ? 'bg-gray-100 dark:bg-gray-500' : ' '}
`;
  return (
    <Tag
      ref={ref}
      className={twMerge(className, derivedClassName)}
      {...derived}
    >
      {children}
    </Tag>
  );
});

export const ListItem: <
  Element extends React.ElementType = typeof defaultElement,
>(
  props: ListItemProps<Element>,
) => React.ReactElement | null = forwardRef(function ListItem(
  {
    as: Tag = defaultElement,
    icon,
    firstRow,
    secondRow,
    button,
    active = false,
    ...derived
  }: ListItemOwnProps,
  ref: React.Ref<Element>,
) {
  return (
    <ListItemContainer as={Tag} {...derived}>
      {icon ? icon : ''}
      <div className="flex flex-1 flex-col items-start">
        <div>{firstRow}</div>
        {secondRow ? (
          <div className="text-gray-500 dark:text-gray-200">{secondRow}</div>
        ) : (
          ''
        )}
      </div>
      {button ? button : ''}
    </ListItemContainer>
  );
});

export default ListItem;
