import { forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type ButtonOwnProps<
  Element extends React.ElementType = React.ElementType,
> = {
  as?: Element;
  variant?: 'filled' | 'outline' | 'text';
  children: ReactNode;
  className?: string | undefined;
  disabled?: boolean;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export type ButtonProps<Element extends React.ElementType> =
  ButtonOwnProps<Element> &
    Omit<React.ComponentProps<Element>, keyof ButtonOwnProps>;

const defaultElement = 'button';

export const Button: <
  Element extends React.ElementType = typeof defaultElement,
>(
  props: ButtonProps<Element>,
) => React.ReactElement | null = forwardRef(function Button(
  {
    variant = 'filled',
    as: Tag = defaultElement,
    icon: Icon,
    children,
    className: derivedClassName,
    disabled,
    ...derived
  }: ButtonOwnProps,
  ref: React.Ref<Element>,
) {
  let className = `inline-flex group justify-center items-center px-4 py-2 gap-2 rounded-lg text-primary-800 hover:text-primary-700 font-bold hover:bg-primary-100  outline-none focus-visible:ring-2 focus-visible:ring-secondary-300 focus-visible:border-transparent ${
    !disabled ? 'active:scale-95' : ''
  }`;

  switch (variant) {
    case 'filled':
      className = `${className} border-2 border-transparent bg-primary-150 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-800 disabled:bg-gray-200 disabled:text-gray-700 `;
      break;

    case 'outline':
      className = `${className} border-2 border-primary-800 dark:border-primary-200 dark:hover:border-primary-100 dark:text-primary-200 dark:hover:text-primary-100 dark:hover:bg-transparent dark:focus-visible:border-transparent disabled:text-gray-400 disabled:border-gray-400 disabled:dark:text-gray-200 disabled:dark:border-gray-200`;
      break;

    case 'text':
      className = `${className} border-2 border-transparent dark:text-primary-200 dark:hover:text-primary-100 dark:hover:bg-transparent disabled:text-gray-700 disabled:dark:text-gray-200`;
      break;

    default:
      break;
  }
  return (
    <Tag
      ref={ref}
      className={twMerge(className, derivedClassName)}
      disabled={disabled}
      {...derived}
      as={undefined}
    >
      {Icon ? <Icon className="h-full" /> : null}
      {children}
    </Tag>
  );
});

export default Button;
