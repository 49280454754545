import { Footer } from '@santicon/web/aherrus/shared/ui';
import { AppProps } from 'next/app';
import useSystemTheme from 'react-use-system-theme';
import Head from 'next/head';
import './styles.css';
import { ClientOnlySuspense } from '@santicon/web/shared/utils';
import { Header, HeaderLogo, Spinner } from '@santicon/web/shared/ui';
import Link from 'next/link';
import React from 'react';
import dynamic from 'next/dynamic';

function CustomApp({ Component, pageProps }: AppProps) {
  const systemTheme = useSystemTheme();
  return (
    <>
      <Head>
        <title>Aherrus Work Tracking</title>
        {systemTheme !== 'dark' && (
          <>
            <link
              rel="alternate icon"
              sizes="16x16"
              type="image/x-icon"
              href="/favicon.ico"
            />
            <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
          </>
        )}
        {systemTheme === 'dark' && (
          <>
            <link
              rel="alternate icon"
              sizes="16x16"
              type="image/x-icon"
              href="/favicon-dark.ico"
            />
            <link rel="icon" type="image/svg+xml" href="/favicon-dark.svg" />
          </>
        )}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="alternate icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="alternate icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#034933" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#000"></meta>
      </Head>
      <div className="flex min-h-full flex-col bg-gray-50 dark:bg-gray-700 dark:text-gray-0">
        <AherrusHeader />
        <main className="mx-auto flex w-full max-w-5xl flex-auto flex-col items-center py-4 px-1 sm:px-4">
          <Component {...pageProps} />
        </main>
        <Footer />
      </div>
    </>
  );
}

const HeaderLinks = dynamic<{ className: string }>(
  () => import('@santicon/web/aherrus/navigation').then(mod => mod.HeaderLinks),
  { ssr: false },
);
const UserMenuFeature = dynamic(
  () =>
    import('@santicon/web/aherrus/navigation').then(mod => mod.UserMenuFeature),
  { ssr: false },
);

function AherrusHeader() {
  return (
    <Header
      logo={
        <Link href="/" passHref>
          <a aria-label="Home">
            <HeaderLogo />
          </a>
        </Link>
      }
    >
      <div className="flex items-center gap-2">
        <ClientOnlySuspense fallback={<Spinner />}>
          <>
            <HeaderLinks className="hidden sm:block" />
            <UserMenuFeature />
          </>
        </ClientOnlySuspense>
      </div>
    </Header>
  );
}

export default CustomApp;
