import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

/* eslint-disable-next-line */
export interface NextBreadcrumbsProps {}

export function NextBreadcrumbs() {
  const router = useRouter();

  const generatePathParts = (pathStr: string) => {
    const pathWithoutQuery = pathStr?.split('?')[0];
    return pathWithoutQuery?.split('/').filter(v => v.length > 0);
  };

  const generateBreadcrumbs = useCallback(() => {
    // Remove any query parameters, as those aren't included in breadcrumbs
    const asPathNestedRoutes = generatePathParts(router.asPath);
    const pathnameNestedRoutes = generatePathParts(router.pathname);

    // Break down the path between "/"s, removing empty entities
    // Ex:"/my/nested/path" --> ["my", "nested", "path"]
    // const asPathNestedRoutes = asPathWithoutQuery
    //   .split('/')
    //   .filter(v => v.length > 0);

    // Iterate over the list of nested route parts and build
    // a "crumb" object for each one.
    const crumblist =
      asPathNestedRoutes?.map((subpath, idx) => {
        // Pull out and convert "[post_id]" into "post_id"
        const param =
          pathnameNestedRoutes &&
          pathnameNestedRoutes[idx].replace('[', '').replace(']', '');
        const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');
        return {
          href,
          title: param,
        };
      }) || [];

    // Add in a default "Home" crumb for the top-level
    return [{ href: '/', title: 'Home' }, ...crumblist];
  }, [router]);

  const crumbs = useMemo(() => generateBreadcrumbs(), [generateBreadcrumbs]);

  return (
    <div>
      <nav>
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
          {crumbs.map((crumb, idx) => (
            <li key={idx} className="flex items-center">
              {idx !== 0 ? <SvgArrow /> : null}
              {idx !== crumbs.length - 1 ? (
                <Link href={crumb.href} passHref>
                  <a className="ml-1 text-sm font-medium capitalize text-primary-700 hover:text-primary-100 dark:text-primary-200 md:ml-2">
                    {crumb.title}
                  </a>
                </Link>
              ) : (
                <span className="ml-1 text-sm font-medium capitalize text-gray-600 dark:text-gray-200 md:ml-2">
                  {crumb.title}
                </span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

const SvgArrow = () => (
  <svg
    className="h-6 w-6 text-gray-400"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default NextBreadcrumbs;
