type milliseconds = number;

interface CalcDurationOptions {
  startTime?: Date;
  endTime?: Date;
  cumulativeDuration?: number;
}

/**
 * Gets cumulative duration as millisecond
 *
 * @param startTime
 * @param endTime
 * @param cumulativeDuration
 * @returns
 */
export function calcDuration({
  startTime = new Date(),
  endTime = new Date(),
  cumulativeDuration = 0,
}: CalcDurationOptions): milliseconds {
  return cumulativeDuration + endTime?.getTime() - startTime?.getTime();
}
