import { Session } from '@santicon/common/aherrus/models';
import { getWeek } from 'date-fns';

export function isNewMonth(
  sessions: Session[],
  index: number,
  session: Session,
): boolean {
  return (
    index === 0 ||
    sessions[index - 1].startTime.getMonth() !== session.startTime.getMonth()
  );
}

export function isNewDay(
  sessions: Session[],
  index: number,
  session: Session,
): boolean {
  return (
    index === 0 ||
    sessions[index - 1].startTime.getDay() !== session.startTime.getDay()
  );
}

export function isNewYear(
  sessions: Session[],
  index: number,
  session: Session,
): boolean {
  return (
    index === 0 ||
    sessions[index - 1].startTime.getFullYear() !==
      session.startTime.getFullYear()
  );
}

export function isNewWeek(
  sessions: Session[],
  index: number,
  session: Session,
): boolean {
  return (
    index === 0 ||
    getWeek(sessions[index - 1].startTime) !== getWeek(session.startTime)
  );
}
