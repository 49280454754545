import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export interface NavLinkProps {
  children: ReactElement;
  className?: string | undefined;
  active?: boolean;
}

function decorateAnchorChildren(children: ReactElement, className: string) {
  if (children.type === 'a') {
    return React.cloneElement(children, {
      className: twMerge(className),
    });
  } else if (children.props?.children) {
    return React.cloneElement(children, {
      className,
    });
  } else {
    throw new Error('One of the child elements should be anchor element');
  }
}

export function NavLink({
  children,
  className: derivedClassName,
  active = false,
  ...derived
}: NavLinkProps) {
  let className =
    ' drop-shadow font-bold rounded-md p-2 outline-none focus-visible:ring-2 focus-visible:ring-secondary-300';

  if (active) {
    className +=
      ' underline decoration-primary-150 drop-shadow-[0_0_4px_rgba(129,254,214,0.5)]';
  }
  const grandChildren = children.props?.children;
  let element;

  if (children.type !== 'a') {
    element = React.cloneElement(
      children,
      {},
      decorateAnchorChildren(
        grandChildren,
        twMerge(className, derivedClassName),
      ),
    );
  } else {
    element = React.cloneElement(children, {
      className: twMerge(className, derivedClassName),
      ...derived,
    });
  }

  return element;
}

export default NavLink;
