import {
  format as fnsFormat,
  formatDistance as fnsFormatDistance,
  formatDuration as fnsFormatDuration,
  formatDistanceStrict as fnsFormatDistanceStrict,
} from 'date-fns';
import { enUS } from 'date-fns/locale';

type FormatDistanceToken =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays'
  | 'aboutXWeeks'
  | 'xWeeks'
  | 'aboutXMonths'
  | 'xMonths'
  | 'aboutXYears'
  | 'xYears'
  | 'overXYears'
  | 'almostXYears';

type FormatDistanceFn = (
  token: FormatDistanceToken,
  count: number,
  options?: {
    addSuffix?: boolean;
    comparison?: -1 | 0 | 1;
  },
) => string;

type FormatDistanceTokenValue = string;

type FormatDistanceLocale<Value> = {
  [token in FormatDistanceToken]: Value;
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export function format(date: Date, formatStr = 'PP', locale: Locale = enUS) {
  // const locale = locales[Localization.locale] ?? enUS;

  return fnsFormat(date, formatStr, {
    locale,
  });
}

export function formatDistance(
  date: Date | number,
  baseDate: Date | number,
  {
    locale = enUS,
    ...options
  }: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
  },
): string {
  // const locale = locales[Localization.locale] ?? enUS;

  return fnsFormatDistance(date, baseDate, {
    locale,
    ...options,
  });
}

const formatDistanceLocale: FormatDistanceLocale<FormatDistanceTokenValue> = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
};

const distanceFormating: FormatDistanceFn = (token, count, options) => {
  let result = '';

  const tokenValue = formatDistanceLocale[token];

  if (typeof tokenValue === 'string') {
    result = tokenValue.replace('{{count}}', count.toString());
  }
  if (options?.addSuffix) {
    if (options?.comparison && options?.comparison > 0) {
      return 'in ' + result;
    } else {
      return result + ' ago';
    }
  }

  return result;
};

export function formatDistanceStrict(
  date: Date | number,
  baseDate: Date | number,
  {
    locale = enUS,
    ...options
  }: {
    addSuffix?: boolean;
    locale?: Locale;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
  },
): string {
  // const locale = locales[Localization.locale] ?? enUS;

  return fnsFormatDistanceStrict(date, baseDate, {
    locale: {
      ...locale,
      formatDistance: distanceFormating,
    },
    ...options,
  });
}

export function formatDuration(
  duration: Duration,
  {
    locale = enUS,
    ...options
  }: {
    format?: string[];
    zero?: boolean;
    delimiter?: string;
    locale: Locale;
  },
) {
  return fnsFormatDuration(duration, {
    locale: { ...locale, formatDistance: distanceFormating },
    ...options,
  });
}
