import * as React from 'react';
import { IconProps } from './types';

export function AherrusIcon({
  size = '1em',
  ...props
}: IconProps & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 298 298"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.5 52C85.816 52 35 102.816 35 165.5S85.816 279 148.5 279 262 228.184 262 165.5 211.184 52 148.5 52zM17 165.5C17 92.875 75.875 34 148.5 34S280 92.875 280 165.5 221.125 297 148.5 297 17 238.125 17 165.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.5 52C85.816 52 35 102.816 35 165.5S85.816 279 148.5 279 262 228.184 262 165.5 211.184 52 148.5 52zM17 165.5C17 92.875 75.875 34 148.5 34S280 92.875 280 165.5 221.125 297 148.5 297 17 238.125 17 165.5z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153 59v21h-10V59h10zM62.5 170.5h-21v-10h21v10zm192 0h-21v-10h21v10zM153 251v21h-10v-21h10z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M98.726 245.845a95.441 95.441 0 004.317 2.524l-6.128 10.613-4.33-2.5 6.141-10.637zM197.536 246.298a93.739 93.739 0 01-4.34 2.484l5.889 10.2 4.33-2.5-5.879-10.184zM228.941 215.118l10.041 5.797 2.5-4.33-10.025-5.788a95.545 95.545 0 01-2.516 4.321zM231.457 120.203l10.025-5.788-2.5-4.33-10.041 5.797a95.545 95.545 0 012.516 4.321zM103.043 82.631l-6.128-10.613-4.33 2.5 6.141 10.637a94.598 94.598 0 014.317-2.524zM67.797 116.308l-10.779-6.223-2.5 4.33 10.786 6.227a95.061 95.061 0 012.493-4.334zM65.304 210.358l-10.786 6.227 2.5 4.33 10.779-6.223a95.061 95.061 0 01-2.493-4.334zM193.196 82.218c1.471.79 2.917 1.619 4.34 2.484l5.879-10.184-4.33-2.5-5.889 10.2z"
        fill="currentColor"
      />
      <path
        d="M98.726 245.845a95.441 95.441 0 004.317 2.524l-6.128 10.613-4.33-2.5 6.141-10.637zM197.536 246.298a93.739 93.739 0 01-4.34 2.484l5.889 10.2 4.33-2.5-5.879-10.184zM228.941 215.118l10.041 5.797 2.5-4.33-10.025-5.788a95.545 95.545 0 01-2.516 4.321zM231.457 120.203l10.025-5.788-2.5-4.33-10.041 5.797a95.545 95.545 0 012.516 4.321zM103.043 82.631l-6.128-10.613-4.33 2.5 6.141 10.637a94.598 94.598 0 014.317-2.524zM67.797 116.308l-10.779-6.223-2.5 4.33 10.786 6.227a95.061 95.061 0 012.493-4.334zM65.304 210.358l-10.786 6.227 2.5 4.33 10.779-6.223a95.061 95.061 0 01-2.493-4.334zM193.196 82.218c1.471.79 2.917 1.619 4.34 2.484l5.879-10.184-4.33-2.5-5.889 10.2z"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M191.359 148.68c0 23.599-11.022 42.964-22.112 56.471a127.973 127.973 0 01-15.239 15.593 106.048 106.048 0 01-5.013 4.071c-.554.42-.998.746-1.315.975a74.38 74.38 0 01-1.316-.975 106.048 106.048 0 01-5.013-4.071 127.902 127.902 0 01-15.238-15.593C115.023 191.644 104 172.279 104 148.68c0-24.124 19.556-43.68 43.68-43.68 24.123 0 43.679 19.556 43.679 43.68zm-44.367 15.435c11.941 0 21.622-9.681 21.622-21.622s-9.681-21.621-21.622-21.621-21.621 9.68-21.621 21.621c0 11.941 9.68 21.622 21.621 21.622z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165 29h-34v-5h34v5zM173 21h-50V1h50v20zM265.219 77.365l-24.042-24.042 14.142-14.142 24.042 24.042-14.142 14.142zM255.198 79.344l-16-16 3.535-3.536 16 16-3.535 3.536z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth={2}
      />
    </svg>
  );
}

export default AherrusIcon;
