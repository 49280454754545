import * as React from 'react';
import { IconProps } from './types';

function Next({
  size = '1em',
  ...props
}: IconProps & React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7 2h4.529v22H7zM32.902 13L13.794 23.99V2.008L32.902 13z"
      />
    </svg>
  );
}

export default Next;
