import React from 'react';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Input, { InputProps } from '../input/input';

/* eslint-disable-next-line */
export interface PasswordInputProps extends InputProps {
  placeholder: string;
  errorText?: string;
}

export function PasswordInput({
  label = 'Password',
  ...derived
}: PasswordInputProps) {
  const [passwordVisible, setpasswordVisible] = useState(false);
  return (
    <Input
      type={passwordVisible ? 'text' : 'password'}
      label={label}
      {...derived}
      button={(buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
        <button
          type={'button'}
          {...buttonProps}
          tabIndex={-1}
          onClick={() => setpasswordVisible(!passwordVisible)}
          onMouseDown={e => e.preventDefault()}
        >
          {passwordVisible ? <FaEyeSlash /> : <FaEye />}
        </button>
      )}
    />
  );
}

export default PasswordInput;
