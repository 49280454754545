import { Transition, Dialog as HeadlessUIDialog } from '@headlessui/react';
import { Fragment, useEffect } from 'react';

/* eslint-disable-next-line */
export interface DialogProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  children?: React.ReactNode;
  isOpen: boolean;
  buttons?: React.ReactNode;
  onClose: () => void;
}

export function Dialog({
  title,
  description,
  isOpen,
  buttons,
  onClose,
  children,
}: DialogProps) {
  useEffect(() => {
    console.log('Dialog mounted');

    return () => {
      console.log('Dialog unmounted');
    };
  }, []);

  return (
    <Transition show={isOpen} as={Fragment}>
      <HeadlessUIDialog
        onClose={onClose}
        className="fixed inset-0 z-10 overflow-y-auto"
      >
        <div className="flex min-h-screen items-center justify-center">
          {/*
          Use one Transition.Child to apply one transition to the overlay...
        */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-1000"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <HeadlessUIDialog.Panel>
              <div className="relative mx-auto flex max-w-sm flex-col gap-4 rounded-lg bg-gray-0 p-8 shadow dark:bg-gray-600 dark:text-gray-0">
                <HeadlessUIDialog.Title className="text-2xl">
                  {title}
                </HeadlessUIDialog.Title>
                <HeadlessUIDialog.Description>
                  {description}
                </HeadlessUIDialog.Description>

                {children}

                {buttons ? buttons : null}
              </div>
            </HeadlessUIDialog.Panel>
          </Transition.Child>
        </div>
      </HeadlessUIDialog>
    </Transition>
  );
}

export default Dialog;
