import { User } from '@santicon/common/shared/models';
import { NextBreadcrumbs } from '@santicon/web/shared/nextjs/ui';
import { Button } from '@santicon/web/shared/ui';

export function EmailNotVerified({
  user,
  onResend,
  onSignout,
}: {
  user: User;
  onResend: () => void;
  onSignout: () => void;
}) {
  return (
    <div className="flex w-full max-w-xl flex-col gap-4">
      <NextBreadcrumbs />
      <h1 className="mb-4 text-center text-4xl font-extrabold drop-shadow">
        Email {user.email} waiting to be verified
      </h1>
      <p>
        Used email is not yet verified. Please check your email and press the
        verify link.
      </p>
      <p>Already verified your email? Please reload the page.</p>
      <Button className="m-2" onClick={() => location.reload()}>
        Reload
      </Button>
      <p>
        If you have not received your verification email, you can resend it.
      </p>
      <Button variant="outline" className="m-2" onClick={onResend}>
        Resend verification email
      </Button>
      <Button variant="text" className="m-2" onClick={onSignout}>
        Sign Out
      </Button>
    </div>
  );
}
