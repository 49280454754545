import { cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export interface FeatureItemProps {
  icon: JSX.Element;
  title: string;
  description: string;
  className?: string;
}

export function FeatureItem({
  icon,
  title,
  description,
  className: derivedClassName,
}: FeatureItemProps) {
  const className =
    'flex w-44 flex-col items-center gap-2 text-center dark:text-gray-0';
  return (
    <div className={twMerge(className, derivedClassName)}>
      <div className="flex h-28 w-28 items-center justify-center rounded-full bg-secondary-300 text-secondary-0">
        {cloneElement(icon, { size: '3rem' })}
      </div>
      <h3 className="text-lg font-bold">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default FeatureItem;
