/* eslint-disable-next-line */
export interface NavigationProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export function Navigation({ children: links, className }: NavigationProps) {
  return (
    <nav className={`${className}`}>
      <ul className="flex gap-2">
        {Array.isArray(links) ? (
          links.map((Link, index) => <li key={index}>{Link}</li>)
        ) : (
          <li>{links}</li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;
