import { ReactNode } from 'react';
import AherrusIcon from '../icons/aherrus';
import AherrusLogo from '../logo/logo';

/* eslint-disable-next-line */
export interface HeaderProps {
  logo: ReactNode;
  children: ReactNode;
}

export function HeaderLogo() {
  return (
    <div className="flex items-center">
      <AherrusIcon
        className="mr-4 text-primary-150"
        height="62px"
        width="62px"
      />
      <AherrusLogo
        className=" mr-4 text-primary-0"
        width="100%"
        height="32px"
      />
    </div>
  );
}

export function Header({ children, logo }: HeaderProps) {
  return (
    <header className="flex min-h-[80px] flex-col  items-center justify-center bg-gray-900 text-primary-150">
      <div className="items-between mx-auto flex w-full max-w-5xl items-center justify-between px-4">
        {logo}
        {children}
      </div>
    </header>
  );
}

export default Header;
