import { Menu, Transition } from '@headlessui/react';
import { User } from '@santicon/common/shared/models';
import { Avatar, ListItem } from '@santicon/web/shared/ui';
import { Fragment } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { MenuItem, MenuLink } from '../menu-link/menu-link';

/* eslint-disable-next-line */
export interface UserMenuProps {
  user: User;
  menuItems: MenuItem[];
  onSignout: () => void;
}

export function AherrusUserMenu({ user, onSignout, menuItems }: UserMenuProps) {
  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button className="relative h-10 ">
            {open ? (
              <FaChevronDown className="mx-auto w-10" />
            ) : (
              <>
                <div className="space-y-2 rounded  p-2 shadow sm:hidden">
                  <span className="block h-0.5 w-6 animate-pulse bg-primary-200"></span>
                  <span className="block h-0.5 w-6 animate-pulse bg-primary-200"></span>
                  <span className="block h-0.5 w-6 animate-pulse bg-primary-200"></span>
                </div>
                <Avatar
                  className="hidden sm:flex"
                  name={user.displayName}
                  src={user.photoURL}
                />
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right  rounded-md bg-gray-900 p-4 text-gray-0 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex w-max flex-col items-center px-1  py-1">
                <Avatar name={user.displayName} src={user.photoURL} />
                <h2 className="font-heading text-lg font-semibold">
                  {user.displayName}
                </h2>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`p-0 font-bold text-primary-200 hover:text-primary-50 ${
                        active ? 'text-primary-50' : ''
                      }`}
                      onClick={e => {
                        e.preventDefault();
                        onSignout();
                      }}
                    >
                      Sign Out
                    </button>
                  )}
                </Menu.Item>
                <div className=" min-w-[13rem] divide-y divide-gray-500">
                  {menuItems.map(({ icon: Icon, name, path }) => (
                    <Menu.Item key={name}>
                      {({ active, ...rest }) => (
                        <MenuLink href={path}>
                          <ListItem
                            as="a"
                            icon={<Icon size={24} />}
                            firstRow={name}
                            button={<FaChevronRight />}
                            active={active}
                            {...rest}
                          />
                        </MenuLink>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default AherrusUserMenu;
