import Link from 'next/link';
import React, { cloneElement } from 'react';

export const MenuLink = React.forwardRef(function MyLink(
  props: {
    href: string;
    children: React.ReactNode;
  },
  ref: React.Ref<HTMLAnchorElement>,
) {
  const { href, children, ...rest } = props;
  return (
    <Link href={href} passHref>
      {cloneElement(children as React.ReactElement, { ...rest, ref })}
    </Link>
  );
});

export interface MenuItem {
  icon: (props: { size: number }) => JSX.Element;
  name: string;
  path: string;
}
