import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

/* eslint-disable-next-line */
export interface TimerContainerProps {
  gap?: string;
  children: ReactNode;
}

interface TimerContextInterface {
  gap: string;
}

const defaultGap = '16px';

const TimerContext = React.createContext<TimerContextInterface>({
  gap: defaultGap,
});

export function useTimerContext() {
  const context = React.useContext(TimerContext);
  if (context === undefined) {
    throw new Error('useTimerContext must be used within TimerContainer');
  }
  return context;
}

export function TimerContainer({
  gap = defaultGap,
  className: derivedClassName,
  children,
}: TimerContainerProps & React.ComponentPropsWithoutRef<'div'>) {
  const className = 'flex flex-col';

  return (
    <TimerContext.Provider value={{ gap }}>
      <div className={twMerge(className, derivedClassName)} style={{ gap }}>
        {children}
      </div>
    </TimerContext.Provider>
  );
}

export default TimerContainer;
