import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type IconButtonOwnProps<
  Element extends React.ElementType = React.ElementType,
> = {
  as?: Element;
  variant?: 'filled' | 'outline';
  className?: string | undefined;
  disabled?: boolean;
  active?: boolean;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

export type IconButtonProps<Element extends React.ElementType> =
  IconButtonOwnProps<Element> &
    Omit<React.ComponentProps<Element>, keyof IconButtonOwnProps>;

const defaultElement = 'button';

export const IconButton: <
  Element extends React.ElementType = typeof defaultElement,
>(
  props: IconButtonProps<Element>,
) => React.ReactElement | null = forwardRef(function IconButton(
  {
    variant = 'filled',
    as: Tag = defaultElement,
    Icon,
    className: derivedClassName,
    active = true,
    ...derived
  }: IconButtonOwnProps,
  ref: React.Ref<Element>,
) {
  let className =
    ' inline-flex group justify-center items-center p-3 gap-2 rounded-full text-primary-800 hover:text-primary-700 hover:border-primary-700 font-bold hover:bg-primary-100 active:scale-95 outline-none focus-visible:ring-2 focus-visible:ring-secondary-300 focus-visible:border-transparent disabled:bg-gray-200 disabled:text-gray-700';

  switch (variant) {
    case 'filled':
      className = `${className} border-2 border-transparent hover:border-transparent bg-primary-150 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-800`;
      break;

    case 'outline':
      className = `${className} border-2 border-primary-800 dark:border-primary-200 dark:hover:border-primary-100 dark:text-primary-200 dark:hover:text-primary-100 dark:hover:bg-transparent dark:focus-visible:border-transparent`;
      break;

    default:
      break;
  }
  return (
    <Tag
      ref={ref}
      className={twMerge(className, derivedClassName)}
      {...derived}
      as={undefined}
    >
      {Icon ? <Icon className="h-full" /> : null}
    </Tag>
  );
});

export default IconButton;
