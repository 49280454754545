import { ReactNode, useId } from 'react';

/* eslint-disable-next-line */
export interface TimerCellProps {
  label: string;
  children: ReactNode;
}

export function TimerCell({ label, children }: TimerCellProps) {
  const labelId = useId();
  return (
    <div className="flex flex-1 flex-col rounded border p-2 dark:border-gray-0">
      <div className="text-sm text-gray-500 dark:text-primary-150" id={labelId}>
        {label}
      </div>
      <div className="font-value dark:text-gray-0" aria-labelledby={labelId}>
        {children}
      </div>
    </div>
  );
}

export default TimerCell;
