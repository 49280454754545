import { Suspense, SuspenseProps } from 'react';
import useIsClient from '../use-is-client/use-is-client';

/* eslint-disable-next-line */
export interface ClientOnlySuspenseProps {}

export function ClientOnlySuspense({ children, fallback }: SuspenseProps) {
  const isClient = useIsClient();

  if (!isClient) return null;

  return <Suspense fallback={fallback}>{children}</Suspense>;
}

export default ClientOnlySuspense;
