import { ReactNode } from 'react';
import { useTimerContext } from '../timer-container/timer-container';

/* eslint-disable-next-line */
export interface TimerRowProps {
  children: ReactNode;
}

export function TimerRow({
  children,
}: TimerRowProps & React.ComponentPropsWithoutRef<'div'>) {
  const { gap } = useTimerContext();
  return (
    <div className="flex" style={{ gap: gap }}>
      {children}
    </div>
  );
}

export default TimerRow;
