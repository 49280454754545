export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
}

export function Checkbox({ label, ...derived }: CheckboxProps) {
  return (
    <label className={`flex items-center gap-2`}>
      <input
        type="checkbox"
        {...derived}
        className={`text-primary-300  checked:hover:bg-primary-150 dark:border-current dark:bg-transparent dark:checked:bg-current dark:checked:hover:bg-primary-150`}
      ></input>
      <span>{label}</span>
    </label>
  );
}

export default Checkbox;
