import { Workday } from '@santicon/common/aherrus/models';
import { Button, ListItem } from '@santicon/web/shared/ui';
import { durationAsText, format } from '@santicon/web/shared/utils';
import Link from 'next/link';
import { FaPlay } from 'react-icons/fa';

/* eslint-disable-next-line */
export interface WorkdayListProps {
  workdays: Workday[];
  workdayId?: string;
}

export function WorkdayList({ workdays, workdayId }: WorkdayListProps) {
  if (!workdays.length) {
    return (
      <div className="text-center">
        <p>There are no tracked workdays yet.</p>
        <Link href="/timer" passHref>
          <Button as="a" className="mt-4">
            Start tracking work
          </Button>
        </Link>
      </div>
    );
  }
  return (
    <ol className="divide-y divide-gray-500">
      {workdays
        ? workdays.map((workday, index, workdays) => {
            const item = (
              <li key={workday.id}>
                <Link
                  href={
                    workdayId === workday.id
                      ? '/timer'
                      : `/journal/${workday.id}`
                  }
                  passHref
                >
                  <ListItem
                    as="a"
                    key={workday.id}
                    icon={workdayId === workday.id ? <FaPlay /> : null}
                    firstRow={format(workday.dayStarted, 'PPP')}
                    secondRow={`${format(workday.dayStarted, 'p')} - ${
                      workday.dayEnded
                        ? format(workday.dayEnded, 'p')
                        : 'onGoing'
                    } ${
                      workday.cumulativeWorkDuration
                        ? '(' +
                          durationAsText(workday.cumulativeWorkDuration) +
                          ')'
                        : ''
                    }`}
                  />
                </Link>
              </li>
            );
            if (
              index === 0 ||
              workdays[index - 1].dayStarted.getMonth() !==
                workday.dayStarted.getMonth()
            ) {
              return (
                <div key={workday.id}>
                  <li className="bg-gray-150 p-2 dark:bg-gray-800">
                    {format(workday.dayStarted, 'LLLL')}
                  </li>
                  {item}
                </div>
              );
            } else {
              return item;
            }
          })
        : null}
    </ol>
  );
}

export default WorkdayList;
